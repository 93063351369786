@import url('https://fonts.googleapis.com/css2?family=Source+Sans+3:ital,wght@0,200..900;1,200..900&display=swap');

.App {
  /* text-align: center; */
  font-family: "Source Sans 3", sans-serif;
  font-optical-sizing: auto;
}

.apply-font{
  font-family: "Source Sans 3", sans-serif;
}

.font-expanded {
  font-stretch: expanded;
}

